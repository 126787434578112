/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      title={title}
      htmlAttributes={{
        lang,
      }}
      titleTemplate={`%s | ${site.siteMetadata.title} | Chrome Extension`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "apple-mobile-web-app-title",
          content: site.siteMetadata.title,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        }, {
          property: `og:image`,
          content: "https://download-all-images.mobilefirst.me/og_image.png",
        },
        {
          property: `og:description`,
          content: metaDescription,
        }, {
          property: `og:image:width`,
          content: `1200`,
        }, {
          property: `og:image:height`,
          content: `630`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:card`,
          content: "summary_large_image",
        }, {
          name: `twitter:description`,
          content: metaDescription,
        }, {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        keywords.length > 0
          ? {
            name: `keywords`,
            content: keywords.join(`, `),
          }
          : [],
      )
        .concat(meta)}
    >
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css"/>
      <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html:
          JSON.stringify({
            "@context": "http://schema.org",
            "@type": "SoftwareApplication",
            "applicationCategory": "http://schema.org/BrowserApplication",
            "name": "Download All Images",
            "description": "This Chrome extension downloads all images from a web page and packs them into a zip file. Easily download hundreds of images from Google Images™, Instagram, Bing image search, etc.",
            "url":"https://download-all-images.mobilefirst.me/",
            "installUrl": "https://chrome.google.com/webstore/detail/ifipmflagepipjokmbdecpmjbibjnakm",
            "screenshot": "https://download-all-images.mobilefirst.me/screenshot.png",
            "operatingSystem": "Chrome",
            "aggregateRating": {
              "reviewCount": 167,
              "@type": "AggregateRating",
              "ratingValue": 3.63,
            },
            "offers": {
              "@type": "Offer",
              "price": 0.0,
              "availability": "http://schema.org/InStock",
              "priceCurrency": "USD",
            },
            "publisher": {
              "@type": "Organization",
              "name": "Mobile First",
              "logo": {
                "@type": "ImageObject",
                "url": "https://mobilefirst.me/images/white_logo.png",
              }
            },
          }),
      }}>
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
