import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import ArrowLeft from '../images/arrow-left.svg'

class Header extends React.Component {

    state = {sticky: false};

    componentDidMount = _ => window.addEventListener('scroll', this.handleScroll.bind(this));

    componentWillUnmount = _ => window.removeEventListener('scroll', this.handleScroll.bind(this));

    handleScroll = _ => {
        const {sticky} = this.state;
        const newState = window.scrollY - window.innerHeight > 0;

        return sticky === newState || this.setState({sticky: newState});
    };

    render() {
        const {siteTitle, isHome} = this.props;
        const {sticky} = this.state;
        const transparent = this.props.transparentHeader ? '' : 'visible';


        const header = (key, className) => <header key={key} className={className + ' ' + transparent}>
            <div className={'content'}>
                <h1 style={{margin: 0}}>
                    <Link to="/" style={{
                        color: `white`,
                        textDecoration: `none`,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    }}>
                        {isHome || <img src={ArrowLeft} alt={'arrow-back'}
                                        style={{width: 17, height: 17, margin: `0 16px 0 0`, display: `block`}}/>}
                        {siteTitle}
                    </Link>
                </h1>
                <div>
                    <a className={'cta'}
                       href={'https://chrome.google.com/webstore/detail/ifipmflagepipjokmbdecpmjbibjnakm'}>
                        Install For Free
                    </a>
                </div>
            </div>
        </header>;

        return [(!sticky ? null : header(2, 'sticky')), header(1)];
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header
