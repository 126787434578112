import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Facebook from "../images/facebook.svg"
import Twitter from "../images/twitter.svg"
import Header from "./header"
import { Link } from "gatsby"
import MF from "../images/mf.png"
import "./layout.css"

const Layout = ({ children, transparentHeader, isHome }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Header isHome={isHome}
                transparentHeader={transparentHeader}
                siteTitle={data.site.siteMetadata.title}/>
        <div style={{
          margin: `0`,
          paddingTop: 0,
        }}>
          <main>{children}</main>
          <footer>
            <p style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}><span style={{ lineHeight: "30px", margin: "0 20px 0 20px" }}>
              We appreciate your support of this free extension!
            </span>
            </p>
            <p style={{ marginTop: "0em" }}>Please share:</p>
            <div className={"share"}>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${
                data.site.siteMetadata.siteUrl}&amp;src=sdkpreparse`}
                 target='_blank' rel="noopener noreferrer"><img src={Facebook} alt={"share on Facebook"}/></a>
              <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                `Check out #DownloadAllImages by @mobilefirstllc ` +
                data.site.siteMetadata.siteUrl)}`} target='_blank' rel="noopener noreferrer"><img
                src={Twitter} alt={"share on Twitter"}/></a>
            </div>
            <div className={"copy-line"}>
              <a href="https://mobilefirst.me" target={"_blank"} rel="noopener noreferrer"
              style={{display:'flex', alignItems:'center'}}>
                <img className={"mf"} src={MF} alt="" aria-label={'Mobile First'}/>
              </a>
              <div>
                <Link to={"/faqs"}>FAQs</Link> &nbsp; &nbsp;
                <Link to={"/privacy"}>Privacy</Link> &nbsp; &nbsp;
                <a href="https://github.com/MobileFirstLLC/download-all-images" target={"_blank"} rel="noopener noreferrer">for Developers</a> &nbsp; &nbsp;
                <a href={"/"} onClick={e => e.preventDefault() & window.scrollTo(0, 0)}>Top</a>
              </div>
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
